import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { fluid: "", tag: "section" } },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: { color: "#7733FF", icon: "mdi-clipboard-text", inline: "" },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("h1", { staticClass: "h5" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("materials")))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VBtn,
            {
              attrs: {
                color: "primary",
                rounded: "",
                absolute: "",
                fab: "",
                top: "",
                right: "",
                "data-test": "MaterialB2BFiles:AddDialog:AddFile",
              },
              on: { click: _vm.handleOpenAddDialog },
            },
            [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
            1
          ),
          _c(VSelect, {
            staticClass: "language-options-select",
            staticStyle: { width: "92px" },
            attrs: {
              "background-color": "#f5f5f5",
              filled: "",
              items: _vm.languageOptions,
              "item-text": "text",
              "item-value": "value",
              "data-test": "MaterialB2BFiles:Language:ChangeLanguage",
            },
            on: { change: _vm.handleSelectLanguage },
            model: {
              value: _vm.selectedLanguage,
              callback: function ($$v) {
                _vm.selectedLanguage = $$v
              },
              expression: "selectedLanguage",
            },
          }),
          _vm.tableFilesLoading
            ? _c(
                "div",
                { staticClass: "items-center" },
                [
                  _c(VProgressCircular, {
                    attrs: { indeterminate: "", size: "60", color: "primary" },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    VTabs,
                    {
                      on: { change: _vm.handleChangeTab },
                      model: {
                        value: _vm.tabs,
                        callback: function ($$v) {
                          _vm.tabs = $$v
                        },
                        expression: "tabs",
                      },
                    },
                    [
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("news_letter")))]),
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("dealboard_libray")))]),
                    ],
                    1
                  ),
                  _c(VSimpleTable, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("file")))]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(" " + _vm._s(_vm.$t("download")) + " "),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(" " + _vm._s(_vm.$t("edit")) + " "),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(" " + _vm._s(_vm.$t("remove")) + " "),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.files, function (file, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(file.name))]),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "px-2 ml-1 btnDownloadFile",
                                  attrs: {
                                    icon: "",
                                    download: file.name,
                                    id: file.id,
                                    name: file.name,
                                    color: "primary",
                                    "min-width": "0",
                                    loading: _vm.fileDownloading === file.id,
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDownloadFile(file)
                                    },
                                  },
                                },
                                [
                                  _c(VIcon, { attrs: { small: "" } }, [
                                    _vm._v("mdi-download"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass:
                                    "px-2 ml-1 secondary btn-download",
                                  attrs: { "min-width": "0", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEditFile(file)
                                    },
                                  },
                                },
                                [
                                  _c(VIcon, { attrs: { small: "" } }, [
                                    _vm._v("mdi-pencil"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "px-2 ml-1",
                                  attrs: {
                                    color: "red",
                                    "min-width": "0",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOpenDialogDeleteFile(
                                        file
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(VIcon, { attrs: { small: "" } }, [
                                    _vm._v("mdi-trash-can"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("AddOrEditFileDialog", {
                attrs: {
                  files: _vm.files,
                  fileToEdit: _vm.fileToEdit,
                  editFile: _vm.editFile,
                  currentTab: _vm.tabs,
                  currentLanguage: _vm.selectedLanguage,
                },
                on: {
                  successFileRequest: function (e) {
                    return _vm.handleSuccessFileRequest(e)
                  },
                  closeDialog: _vm.handleCloseDialog,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteDialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: { obj: _vm.deleteObj, name: _vm.deleteObj.name },
                on: {
                  close: function ($event) {
                    _vm.deleteDialog = false
                  },
                  delete: _vm.deleteFile,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }