import { VCard } from 'vuetify/lib/components/VCard';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VCard, { staticClass: "dxa-modal video-container" }, [
    _c("div", { staticClass: "embed-container" }, [
      _vm.video.videoPlatform === _vm.videoPlatformEnum.VIMEO
        ? _c("iframe", {
            attrs: {
              title: "Video",
              src:
                "https://player.vimeo.com/video/" +
                _vm.video.videoId +
                "?portrait=0&byline=0&title=0",
              frameborder: "0",
              allowfullscreen: "",
            },
          })
        : _vm._e(),
      _vm.video.videoPlatform === _vm.videoPlatformEnum.YOUTUBE
        ? _c("iframe", {
            attrs: {
              title: "Video",
              src:
                "https://www.youtube.com/embed/" +
                _vm.video.videoId +
                "?rel=0,showinfo=0,controls=0",
              frameborder: "0",
              allowfullscreen: "",
            },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }