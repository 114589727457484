import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { class: _vm.gs.isMobile() ? "" : "dxa_modal" },
    [
      _c(VCardTitle, [
        _c("h4", { staticClass: "dxa_modal_title h4" }, [
          _vm._v(_vm._s(_vm.$t("add_or_edit_file"))),
        ]),
      ]),
      _c(
        VCardText,
        [
          _c(
            VForm,
            {
              ref: "form",
              staticClass: "mt-5",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.checkForm()
                },
              },
            },
            [
              _c(VSelect, {
                attrs: {
                  label: _vm.$t("index"),
                  items: _vm.filesIndexOptions,
                  rules: [_vm.required],
                  outlined: "",
                  loading: _vm.indexLoading,
                },
                model: {
                  value: _vm.fileObject.index,
                  callback: function ($$v) {
                    _vm.$set(_vm.fileObject, "index", $$v)
                  },
                  expression: "fileObject.index",
                },
              }),
              _c(VSelect, {
                attrs: {
                  items: _vm.languageOptions,
                  label: _vm.$t("language"),
                  rules: [_vm.required],
                  outlined: "",
                  "item-text": "text",
                  "item-value": "value",
                },
                on: { change: _vm.handleChangeLanguage },
                model: {
                  value: _vm.fileObject.language,
                  callback: function ($$v) {
                    _vm.$set(_vm.fileObject, "language", $$v)
                  },
                  expression: "fileObject.language",
                },
              }),
              _c(VSelect, {
                attrs: {
                  items: _vm.typeOptions,
                  label: _vm.$t("file_type"),
                  rules: [_vm.required],
                  outlined: "",
                  "item-text": "text",
                  "item-value": "value",
                },
                on: { change: _vm.handleChangeType },
                model: {
                  value: _vm.fileObject.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.fileObject, "type", $$v)
                  },
                  expression: "fileObject.type",
                },
              }),
              _c(
                "div",
                [
                  _vm.fileName
                    ? _c(
                        VListItem,
                        { on: { click: _vm.removeDocument } },
                        [
                          [
                            _c(
                              VIcon,
                              {
                                staticClass: "icon-file-close",
                                attrs: { "aria-label": "Close" },
                              },
                              [_vm._v(" mdi-close ")]
                            ),
                          ],
                          _c(VListItemTitle, [
                            _vm._v(_vm._s(_vm.fileToEdit.name)),
                          ]),
                        ],
                        2
                      )
                    : _c(VFileInput, {
                        attrs: {
                          prefix: "",
                          label: "File input",
                          rules: [_vm.required],
                          accept: "pdf/*",
                        },
                        on: { change: _vm.handleSelectFile },
                      }),
                ],
                1
              ),
              _c(
                VCardActions,
                { staticClass: "pl-0 dxa_modal_actions" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        color: "primary",
                        "min-width": "100",
                        type: "submit",
                        loading: _vm.loading,
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "red", "min-width": "100" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                ],
                1
              ),
              _vm.errorData
                ? _c("AlertErrorComponent", {
                    attrs: { errorData: _vm.errorData },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }