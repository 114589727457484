import { VAlert } from 'vuetify/lib/components/VAlert';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errorData
    ? _c(
        VAlert,
        { staticClass: "ma-2", attrs: { type: "error" } },
        _vm._l(_vm.errorData, function (error, i) {
          return _c("ul", { key: i }, [
            _c("li", [_vm._v(_vm._s(_vm.$t(error)))]),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }