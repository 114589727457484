import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { class: _vm.gs.isMobile() ? "" : "dxa_modal" },
    [
      _c(VCardTitle, [
        _c("h4", { staticClass: "dxa_modal_title h4" }, [
          _vm._v(_vm._s(_vm.$t("add_or_edit_file"))),
        ]),
      ]),
      _c(
        VCardText,
        [
          _c(
            VForm,
            {
              ref: "form",
              staticClass: "mt-5",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.checkForm()
                },
              },
            },
            [
              _c(VSelect, {
                attrs: {
                  label: _vm.$t("index"),
                  items: _vm.videosIndexOptions,
                  rules: [_vm.required],
                  loading: _vm.indexLoading,
                  outlined: "",
                },
                model: {
                  value: _vm.videoObject.index,
                  callback: function ($$v) {
                    _vm.$set(_vm.videoObject, "index", $$v)
                  },
                  expression: "videoObject.index",
                },
              }),
              _c(VSelect, {
                attrs: {
                  items: _vm.languageOptions,
                  label: _vm.$t("language"),
                  rules: [_vm.required],
                  outlined: "",
                  "item-text": "text",
                  "item-value": "value",
                },
                on: { change: _vm.getVideosByLanguage },
                model: {
                  value: _vm.videoObject.language,
                  callback: function ($$v) {
                    _vm.$set(_vm.videoObject, "language", $$v)
                  },
                  expression: "videoObject.language",
                },
              }),
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  rules: [_vm.required],
                  color: "primary",
                  label: _vm.$t("video_title"),
                },
                model: {
                  value: _vm.videoObject.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.videoObject, "title", $$v)
                  },
                  expression: "videoObject.title",
                },
              }),
              _c(VTextField, {
                attrs: {
                  outlined: "",
                  rules: [_vm.required],
                  color: "primary",
                  label: _vm.$t("video_code"),
                },
                model: {
                  value: _vm.videoObject.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.videoObject, "link", $$v)
                  },
                  expression: "videoObject.link",
                },
              }),
              _c(
                "div",
                [
                  _c("span", { staticClass: "video-platform-title" }, [
                    _vm._v(_vm._s(_vm.$t("media_plataform"))),
                  ]),
                  _c(
                    VRadioGroup,
                    {
                      staticClass: "mt-2",
                      attrs: { center: "", row: "" },
                      model: {
                        value: _vm.videoObject.platform,
                        callback: function ($$v) {
                          _vm.$set(_vm.videoObject, "platform", $$v)
                        },
                        expression: "videoObject.platform",
                      },
                    },
                    [
                      _c(VRadio, {
                        attrs: {
                          label: _vm.$t("vimeo"),
                          value: _vm.videoPlatformEnum.VIMEO,
                        },
                      }),
                      _c(VRadio, {
                        attrs: {
                          label: _vm.$t("youtube"),
                          value: _vm.videoPlatformEnum.YOUTUBE,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.thumbnailName
                    ? _c(
                        VListItem,
                        { on: { click: _vm.removeDocument } },
                        [
                          [
                            _c(
                              VIcon,
                              {
                                staticClass: "icon-file-close",
                                attrs: { "aria-label": "Close" },
                              },
                              [_vm._v(" mdi-close ")]
                            ),
                          ],
                          _c(VListItemTitle, [
                            _vm._v(_vm._s(_vm.thumbnailName)),
                          ]),
                        ],
                        2
                      )
                    : _c(VFileInput, {
                        attrs: {
                          prefix: "",
                          label: "File input",
                          rules: [_vm.required],
                        },
                        on: { change: _vm.handleSelectThumbnail },
                      }),
                ],
                1
              ),
              _c(
                VCardActions,
                { staticClass: "pl-0 dxa_modal_actions" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        color: "primary",
                        "min-width": "100",
                        type: "submit",
                        loading: _vm.loading,
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "red", "min-width": "100" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                ],
                1
              ),
              _vm.errorData
                ? _c("AlertErrorComponent", {
                    attrs: { errorData: _vm.errorData },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }